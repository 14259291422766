import React from 'react'
import "./Footer.css"
import { Links, FooterLinks } from '../data'
export default function Footer() {
  return (
    <div>
    <div className='footer'>
        <div className="links">
            <h5>Site Map.</h5>
        {
            Links.map( (link, i)=>{
                return (
                <div className="linkcontainer"  key={i}>
                <div 
               
                custom={i}
               
               > 
                    <a href={link.href}>{link.title}</a>
                </div>
                </div>
                )
            })
        }
        </div>




        <div className="logo">
            <img src='/Assets/Zephr Logo ROUND BLACK.png' alt="" height={280} width={280}/>
            <div className='terms'>
            <p>© ZEPHR. 2024</p>
            <p><a href="terms.html">Terms of Use.</a></p>
            <p><a href="privacy.html">Privacy Policy.</a></p>
            </div>
            <p className='para'>Zephyrs of creativity, our hallmark in design's embrace,<br/>
Where innovation whispers, in every gifting grace.<br/>
Zestful creations, tailored uniquely for you,<br/>
At Zephr, crafting excellence, our passion shines through
</p>
        </div>



        <div className="link2">
            <h5 >Connect with us.</h5>
        {
            FooterLinks.map((link, i)=>{
                 return(
                    
                        <a 
                        key={i}
                        href={link.href}
                        custom={i}
                       >
                            {link.title}
                        </a>
                       
                 ) 
            }
            )
        }
        </div>
       
           

    </div>
   
      
       </div>
       
       
  

  )
}
