import React from 'react';
import Navbar from '../Components/Nav/Nav';
import Footer from '../Components/Footer/Footer';
import ProductsList from '../Components/ProductPage/ProductsList';
import ContactButton from '../Components/ContactUsButton/ContactButton';





const containerStyles = {
  width: '95%',
  height: '140vh',
  display: 'grid',
  overflow: 'hidden',
  margin: '0',
  listStyle: 'none',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  gap: '15px',
  padding: '5px',
 
  borderRadius: '20px',
  position: 'relative', 
};

const itemStyles = {
  background: 'white',
  borderRadius: '2%',
  
};

function ProductsPage() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `./Assets/Zephr Catalogue (2).pdf`;
    link.download = 'Zephr Catalogue.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <Navbar />

      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        <ul  style={containerStyles} >
          {/* Video */}
          <video
            autoPlay
            loop
            muted
            src="./Assets/Final Comp_1.mp4"
            alt="vid"
            className="item"
            
            style={{ ...itemStyles, gridColumn: 'span 2', width: '100%', height: '100%', objectFit: 'cover' }}
          />

          {/* Heading */}
          <div  style={{ ...itemStyles, gridColumn: 'span 2', display:'flex', justifyContent:'space-between' }}>
             <div style={{minWidth:'30%'}}>
            <h1 style={{fontFamily:'futura',margin:'auto', textTransform:'uppercase', fontSize:'6vh', paddingLeft:'20%', paddingTop:'20%'}}> Explore Zephr.</h1>
             </div>
             
             <div style={{maxWidth:'50', fontSize: '1rem', margin: 'auto', transform:'translateX(-60px)' }}>
             <br/><br/><br/>We create high quality, sustainable, luxurious products - stationary, diaries, bags, bottles. Basically, things that carry essence of your brand. The best part? With every single purchase, you have the potential to increase the course of your brand's reach by manyfolds.
             <br/><br/><a onClick={handleDownload}>Download Catalogue.</a>
             </div>
            
       

          </div>
          
        
        
        </ul>
      </div>

      <ProductsList />



      <ContactButton/>

      <Footer />
    </div>
  );
}

export default ProductsPage;
