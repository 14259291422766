'use client'
import React, { useState } from 'react';
import Button from './Button/Button';
import './Header.css';
import { AnimatePresence, motion } from 'framer-motion';
import Nav from './Nav/Nav2';

const variants = {
  open: {
    height: 450,
    width: 350,
    top: '-20px',
    right: '0px',
    transition: { duration: 0.75, ease: [0.76, 0, 0.24, 1] },
  },
  closed: {
    width: 60,
    height: 20,
    top: 0,
    right: 15,
    transition: { duration: 0.75, delay: 0.35, ease: [0.76, 0, 0.24, 1] },
  },
};


const Header = () => {
    const [isActive, setIsActive] = useState(false);
  
    return (
        <div className="header">
        <motion.div
        className="menu"
        variants={variants}
        animate={isActive?"open":"closed"}
        initial="closed"
        >
        <AnimatePresence>
        {isActive && <Nav/> }
        </AnimatePresence>
        </motion.div>
       
        <Button isActive={isActive} setisActive={setIsActive} />
    </div>
    );
  };
  

export default Header;
