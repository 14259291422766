import React from 'react'
import { Links, FooterLinks } from '../../data'
import "./Nav2.css"
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const perspective={
    initial:{
        opacity:0,
        rotateX:90
    
    },
    enter:(i)=>({
        opacity:1,
        rotateX:0,
     
        transition:{
            duration:0.65,
            opacity:{duration:0.35},
            delay:0.5 + (i*0.1)},
            ease:[.215, .61, .355, 1]
    }),
    exit:{
        opacity:0
    }
}
const slideIn={
    initial:{
        opacity:0,
        y:20
        
    
    },
    enter:(i)=>({
        opacity:1,
        y:0,
     
        transition:{
            duration:0.5,
           
            delay:0.75 + (i*0.1)},
            ease:[.215, .61, .355, 1]
    }),
    exit:{
        opacity:0
    }
}


export default function index() {
   

    return (
    <div className="nav">
    <div className="body">
        {
            Links.map( (link, i)=>{
                return (
                <div className="linkcontainer"  key={i}>
                <motion.div 
               
                custom={i}
                variants={perspective}
                animate="enter"
                exit="exit"
                initial="initial"> 
                    <Link to={link.to}>{link.title}</Link>
                </motion.div>
                </div>
                )
            })
        }
        
    </div>    
    <div className="footer">
        {
            FooterLinks.map((link, i)=>{
                 return(
                    
                        <motion.a 
                        key={i}
                        href={link.href}
                        custom={i}
                        variants={slideIn}
                        animate="enter"
                        exit="exit"
                        initial="initial">
                            {link.title}
                        </motion.a>
                  
                 )
            })
        }

    </div>
    
    </div>
  )
}
