import React from 'react'
import './About.css';
import Accord from './Accordion';


function About() {
  return (
    <div className="aboutmain">
    <div className='about'>
      <div className='yaya A'>
      <h1 className='who'>Who are</h1>
      </div>
      <div className="yaya B">
      <img className='logophoto' src='../Assets/Zephr Logo SQUAR BLACK.png' alt="Zephr.in Zephr New Delhi"></img>
         </div>
         <div className="yaya C">
        <h1 className='who'>We?</h1>
          
         </div>
        <div className="yaya D"> <p className='intro'> At Zephr, we take pride in revolutionizing the realms of corporate gifting and printing services. 
        Based in the heart of Delhi, we have established ourselves as industry pioneers,
         offering a unique blend of creativity, precision, and cutting-edge technology.</p></div>
    </div>

    <div style={{width:'95vw'}} className="promovideo">
    <video autoPlay loop muted style={{width:'100%'}}src="/Assets/Final Comp_1.mp4"></video>
    </div>

    <Accord/>


    </div>
  )
}

export default About