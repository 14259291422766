"use client"

import { motion, useScroll, useTransform } from 'framer-motion';
import './Landing.css';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.5, 
      staggerChildren: 0.4,
      duration: 1, 
      ease: "easeInOut"
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6, 
      ease: "easeInOut"
    }
  }
};

export default function Home() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `./Assets/Zephr Catalogue (2).pdf`;
    link.download = 'Zephr Catalogue.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], [250, -400]);
  const z = useTransform(scrollYProgress, [0, 1], [100, -400]);
  return (
    <div 
    
     className="main">
      <div className="page1">
      <motion.div
    className="container"
    variants={container}
    initial="hidden"
    animate="visible"
  >
        <motion.h1 className="item" variants={item}>Zephr.</motion.h1>
        <motion.h1 className="item" variants={item}>Cutting edge.</motion.h1>
        </motion.div>
        <div className="videoContainer">
          <video autoPlay loop muted src="/Assets/Animation.mp4"></video>
        </div>
      </div>
     
      <div className="about">
        <div className="whoarewe">
        <h2>what is zephr</h2>
        <p>Welcome to Zephr, a distinguished name in the world of bespoke corporate gifting and design services, proudly based in New Delhi, Bharat.<br/> <br/> We specialize in tailoring unique gifts and creating sophisticated designs that speak volumes about your brand identity.<br/>
<br/>
What sets us apart is our unwavering dedication to understanding your distinct requirements and translating them into tangible, memorable products. <br/>
</p>
      </div>
       <div className="photowe">
        <img src="./Assets/1111.webp" alt="whatszephr" />
       </div>
       <div className="redefine">
        <img src="./Assets/redefine.png" alt="whoarwe" height={400} width={"auto"}/>
       </div>
      </div>
      <h1 className='tag-line'>TOP PRODUCTS</h1>
    <hr/>
      <div className="page2" >
       
        <div  className="elem" id="elem1">
          <motion.img style={{y}} alt="product" src="/Assets/2022_05_19_5_of_37.webp" />
          <motion.div style={{z, backgroundColor:"#d1d0cb"}} className="labelle">
            <h3>
              Diaries
            </h3>
            
          </motion.div>
        </div>
        <div className="elem" id="elem2">
          <motion.img style={{y}}  src="/Assets/rm372-363-b08-google-mockup.webp" alt="" srcSet="" />
          <motion.div style={{z, backgroundColor:"#a2a092"}} className="labelle1" >
          <h3>
              Bottles
            </h3>
          </motion.div>
        </div>
        <div className="elem" id="elem3">
          <motion.img style={{y}}  src="/Assets/OQ3A7S1.webp" alt="" srcSet="" />
          <motion.div style={{z, backgroundColor:"#8a887e"}}  className="labelle2">
          <h3>
              Mugs
            </h3>
          </motion.div>
        </div>
      </div>
      
      <div className='second'>
        <div className="quote">
          <h1> we believe in branding, where belief meets visibility, forging connections that outshine mediocrity.</h1>
        </div>
        <div className="product">
<p>"Discover premium customized corporate gifting solutions in India. Our extensive range includes custom diaries, notebooks, water bottles, mugs, memo pads, visiting cards,
   and innovative design services tailored to elevate your brand's presence."
   <br/>
  
   <a onClick={handleDownload}>Download Catalogue.</a>
   </p>
       
        </div>
      </div>
       
      </div>
    
  );
}
