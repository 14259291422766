import React from 'react';
import './contactbutton.css';

const openMail = () => {
  window.open("https://wa.me/+919711284226?text=Hey,%20let's%20connect", "_blank");
};

const ContactButton = () => {
  return (
    <div className="contact-button" onClick={openMail}>
      <img src="./Assets/phone.png" alt="callus" />
    </div>
  );
};

export default ContactButton;
