// ProductList.js
import React from 'react';
import './ProductList.css';  // Import the CSS file

const Product = ({ products }) => {
  return (
    <div className="product-list-container">
      <ul className="raju">
        {products.map((product, index) => (
          <div key={index} className="balli">
            <div className="title-container">
              <h1 className="title">{product.title}</h1>
            </div>
            <div className="details-container">
              <div className="photocover">
                <img alt={product.title} src={product.image} className="product-image" />
              </div>
              <div className="description">{product.description}</div>
              <ul className="features-list">
                {product.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Product;
