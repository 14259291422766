import React from 'react'
import Products from './products'


const productList = [
  {
    title: 'Memo Cube',
    image: './Assets/cubesample.jpg',
    description: 'Also known as Table Top Memo Cube, available in 4x4 and 3x3 sizes, created with highest quality sunshine paper (80gsm), available with boxed packing, clear sealed cover or non-woven pouch',
    features: ['Latest Edge Printing Technology', 'Water Mark Printing'],
  },
  {
    title: 'Diary / Notebooks',
    image: './Assets/333.png',
    description: 'Customised Diaries with your logo on Title, along with Latest Edge Printing to enhance branding in an innovative way. Made with high quality craft paper, comes with inside of your choice. A variety of Size options are available ranging from A5 to Larger sizes, 2 Color inner profile along with Planners and Calenders',
    features: ['Latest Edge Printing Technology', 'Custom Titles', 'Recycled Paper Available'],
  },
  {
    title: 'Mugs/Bottles',
    image: './Assets/rm372-363-b08-google-mockup.webp',
    description: "Make your corporate gifts special with our customized mugs and bottles. Add a personal touch by putting your logo or firm names on them. They're stylish, practical, and memorable presents for your clients and team.",
    features: ['All ranges of mugs and bottles available like SS, Thermo, Temp Display'],
  },
  {
    title: 'Ribbon/Tape',
    image: './Assets/ccc.png',
    description: "Unwrap the art of thoughtful giving with our bespoke custom ribbons and tapes. Infuse each gift with your brand's essence or personal touch through unique logos or names. Elevate your corporate gestures with these distinctive ribbons, creating memorable moments that stand out among the ordinary.",
    features: ['Custom Ribbons with your logo/brand name', 'Custom Tapes also available'],
  },
  {
    title: 'Stationary Combos',
    image: './Assets/1111.webp',
    description:   
"Revamp your workspace with our Custom Corporate Stationery Combo – a personalized set that suits your style. Choose from notebooks, pens, and desk essentials to create a unique and practical gift for your team or clients.",
    features: ['A huge range of stationary items available'],
  },
  {
    title: 'Mobile Stands',
    image: './Assets/3a4b370a0041cef4ba68527a000152a3.jpg',
    description: 
    "Stand out in the tech world with our Customized Mobile Stands. Tailored to your preferences, these sleek stands combine functionality with personal style. Elevate your workspace or give a unique gift that holds devices securely and reflects your individuality in a tech-savvy way.",
    features: ['MDF, Plastic, Metal builds','Keychains, Pen Stands, Paper Clips also available'],
  },
  {
    title: 'Coasters',
    image: './Assets/18001782.png',
    description: "Unwrap the art of thoughtful giving with our bespoke custom ribbons and tapes. Infuse each gift with your brand's essence or personal touch through unique logos or names. Elevate your corporate gestures with these distinctive ribbons, creating memorable moments that stand out among the ordinary.",
    features: ['Various shapes and sizes available', 'Rubber, Stainless Steel, MDF variants available'],
  },
  {
    title: 'Plant Pods',
    image: './Assets/podpod.png',
    description: "Bring nature into your corporate gifting with our plant pods. These living gifts offer a breath of fresh air to any workspace. Each pod is a miniature oasis, creating a unique and sustainable impression. Elevate your gift-giving with a touch of green, fostering growth and positivity in every gesture.",
    features: ['Customized logo imprinted planters'],
  },
 
];




export default function ProductsList() {
  return (
  <Products products={productList} />
  )
}


