import React from 'react';
import './App.css';
import Nav from "./Components/Nav/Nav"
import Clients from "./Components/Clients/Clients"
import Landing from "./Components/Landing/Landing"
import Footer from './Components/Footer/Footer';
import Products from './Components/Products/Products';
import ContactButton from './Components/ContactUsButton/ContactButton';



function App() {
  

  return (
  

    <div className="App">
    
     <Nav />

     
     
     <Landing />
     
     <Products />
     
     <Clients />
  
     <Footer/>
      <ContactButton/>
    
    </div>
  );
}

export default App;
