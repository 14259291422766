
import React from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';

const Navbar = () => {
  return (
    <nav className="navbar"> 
      <div className="nav1">
        <a href="/">
      <img src='../Assets/c.png' alt='Logo' width={80} height={80} style={{paddingLeft:'1%'}} /> 
      </a>
      </div>
      <div className="nav2"> 
        <ul className="navbar-list">
         
         
          <li className="navbar-item"><Link to="/ProductsPage"><img src="Assets/shop-svgrepo-com.svg" alt="products" style={{ height:29}} ></img></Link></li>
        
          <li className="navbar-item"><a href="/"><img src="Assets/home-2-svgrepo-com.svg" alt="products" style={{ height:30}} ></img></a></li>
        </ul>
      </div>
      <Header />
    </nav>
  );
}

export default Navbar;
