import React from 'react';

const ArrowIcon = ({ isOpen }) => {
  const arrowStyle = {
    marginLeft: '5px',
    marginRight: '15px',
    
    transition: 'transform 0.3s ease',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    display: 'inline-block', 
  };

  return (
    <span style={arrowStyle}> <img style={{height:15}} src='./Assets/down_2486595.png' alt='aboutzephr' /> </span>
  );
};

export default ArrowIcon;
