import React from 'react'
import './Products.css'

export default function Products() {
  return (
    
          <div className="page3">
           
           <div className="child" id="child1">
               <img src="/Assets/duct-tape-mockup-v7-isometric.webp" alt="" srcset="" />
               
               <p> CUSTOM RIBBONS</p>
           </div>
           <div className="child" id="child2">
               <img src="/Assets/long_tin_box_30_0048.webp" alt="" srcset="" />
               <p> TABLE TOP MEMO PADS</p>
           </div>
           <div className="child" id="child3">
               <img src="/Assets/scene-with-bag-mockups-v6-front-view.webp" alt="" />
               <p> BAGS</p>
           </div>
           <div className="child" id="child4">
               <img src="/Assets/envelope-and-paper-mockups-with-bust-front.webp" alt="" />
               <p> OFFICE STATIONARY</p>
           </div>

    


   

   


    
    </div>
  )
}
