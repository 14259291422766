import React, { useState } from 'react';
import './Accordion.css'
import ArrowIcon from './ArrowIcon';


const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

 
  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const contentStyle = {
      display: isActive ? 'block' : 'none',
      padding: '10px',
      
      
      borderRadius: '5px',
      margin: '5px 0',
      transition: 'display 0.3s ease'
    };

 

    return (
        <div key={index}>
          <div
          onClick={() => onTitleClick(index)}
          className={`accordion-title ${isActive ? 'active' : ''}`}
        >
            {item.title}

            <ArrowIcon isOpen={isActive} />
          </div>
          <div className='accordion-content' style={contentStyle}>
            {isActive && <p>{item.content}</p>}
          </div>
        </div>
      );
    });

  return <div className='accordion-container'>{renderedItems}</div>;
};





const Accord = () => {
  const accordionItems = [
    { title: 'Our Journey', content: <div className='insidecontent'> 
    
    <div className="contenttext">
    Zephr's journey is a testament to our commitment to crafting exceptional experiences through top-notch, cutting-edge products. We have traversed the market landscape, honing our skills and embracing a spirit of constant evolution. Today, Zephr proudly presents a diverse range of products tailored for every brand, business, and multinational agency.</div>
    <div className="contentanimation">
        <img src='./Assets/Journey.png' alt="Zephr Corporate Gifting Journey"/>
    </div></div>},
    { title: 'Tailored Excellence for All', content: <div className='insidecontent'> 
   
    <div className="contenttext">
    At Zephr, we understand that each entity is unique, and our product offerings reflect this understanding. Whether you are a well-established brand, a growing business, or a dynamic multinational agency, we have meticulously crafted products that align with your vision and values. Our commitment to delivering tailored excellence is unwavering.</div>
    <div className="contentanimation">
        <img src='./Assets/excellence.png' alt="Zephr Corporate Gifting Journey"/>
    </div>
    </div> },
    { title: 'Cutting-Edge Solutions', content: <div className='insidecontent'> 
   
    <div className="contenttext">
    <h3>A Commitment to Innovation</h3>

Zephr is not just a brand; it's a commitment to staying at the forefront of innovation. We constantly push boundaries, exploring the latest technologies to bring you cutting-edge solutions. Our products are not just items; they are expressions of sophistication and creativity that set new standards in the market.</div>
<div className="contentanimation">
        <img src='./Assets/cuttingedge.png' alt="Zephr Corporate Gifting Journey"/>
    </div>
    
    </div> },
    { title: 'Our Values', content: <div className='insidecontent'> 
   
    <div className="contenttext">
    <h3>A Commitment to Innovation</h3>

    Quality Beyond Compare: Zephr products are synonymous with quality. Each product undergoes rigorous testing to ensure it meets the highest standards of craftsmanship and durability.
<br/>
Personalized Touch: Our products are not one-size-fits-all. We take pride in offering personalized solutions that resonate with the unique identity of each client.
<br/>
Global Reach, Local Presence: Zephr caters to a diverse clientele globally while maintaining a local touch. Our products are crafted to meet the needs of the global market, yet infused with the warmth of our New Delhi roots.</div>
<div className="contentanimation">
        <img src='./Assets/values.png' alt="Zephr Corporate Gifting Journey"/>
    </div>
    
    </div> },
    { title: 'Our Mission', content: <div className='insidecontent'> 
  
    <div className="contenttext">
    

    As we continue to script our success story, we invite you to embark on this journey of excellence with Zephr. Explore our range of unparalleled products designed to elevate your brand, business, or agency. Join us in shaping the narrative of sophistication and innovation.

Elevate. Innovate. Zephr - Where Excellence Meets Expression..</div>
<div className="contentanimation">
        <img src='./Assets/mission.png' alt="Zephr Corporate Gifting Journey"/>
    </div>
    
    </div> },
  ];

  return (
    <div>
    
      <Accordion items={accordionItems} />
    </div>
  );
};

export default Accord;
