import React from 'react'
import "./Button.css"
import { motion } from 'framer-motion'

const index = ({isActive, setisActive}) => {
  return (
    <div onClick={()=>{setisActive(!isActive)}} className="button">
      
    <motion.div
     className="slider"
     animate={{top : isActive?"-100%":"0"}}
     transition={{duration:0.5, ease:[0.76, 0, 0.24, 1]}}
     >
    <div className="el">
      <Perspectivetext label="Menu" />
      
    </div>
    <div className="el">
      <Perspectivetext label="Close" />
    
    </div>
  </motion.div>
  </div>
  )
}

function Perspectivetext({label}){
  return (
    <div className="perspectivetext">
      <p>{label}</p>
      <p>{label}</p>
      
    </div>
  )
}

export default index