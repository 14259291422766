import React from 'react';
import Navbar from '../Components/Nav/Nav';
import Footer from '../Components/Footer/Footer';
import "./ContactUs.css"
function ContactUs() {
  return (
    <div>
      <Navbar />
      <h1 className="contact-heading">Contact Us</h1>
      <div className='ContactHead'>
        <h6 className="info-text">To get more information about the products, connect with us through</h6>
        <div className='contact-links'>
          <a href="https://wa.me/+91971128226?text=Hey,%20let's%20connect" target="_blank" rel="noreferrer">
            <img className="contact-icon" src='./Assets/whatsapp.png' alt="whatsapp" />
          </a>
          <a href="https://www.instagram.com/zephr.in_/" target="_blank" rel="noreferrer">
            <img className="contact-icon" src='./Assets/insta.png' alt="zephrinsta" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61557321862108" target="_blank" rel="noreferrer">
            <img className="contact-icon" src='./Assets/facebook.png' alt="zephrfacebook" />
          </a>
          <a href="mailto:contact@zephr.in" target="_blank" rel="noreferrer">
            <img className="contact-icon" src='./Assets/maill.png' alt="zephrmaill" />
          </a>
        </div>
        <div className="google-map-code">
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.394034012791!2d77.20242167550352!3d28.647918183381837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02777f4c846f%3A0xdc97138222d6010b!2sV.K.%20Stickers!5e0!3m2!1sen!2sin!4v1706777365368!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
