import React from 'react'
import Navbar from '../Components/Nav/Nav'
import Footer from '../Components/Footer/Footer'

function Blog() {
  return (
    <div>
         <Navbar/>
       
        

       <Footer/>
    </div>
  )
}

export default Blog