import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter,
RouterProvider } from 'react-router-dom';
import ProductsPage from './Pages/ProductsPage';
import PrintingServices from "./Pages/PrintingServices";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs"
import Blog from "./Pages/Blog"


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/ProductsPage",
    element: <ProductsPage />,
  },
  {
    path: "/PrintingServices",
    element: <PrintingServices />,
  },
  {
    path: "/AboutUs",
    element: <AboutUs />,
  },
  {
    path: "/ContactUs",
    element: <ContactUs />,
  },
  {
    path: "/Blog",
    element: <Blog />,
  }

]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
     <RouterProvider router={router} />
  </React.StrictMode>
);
