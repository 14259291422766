export const Links =[
    {
        title:"Products",
        to:"/ProductsPage"
       
    },
    {
        title:"About Us",
        to:"/AboutUs"
    },
    {
        title:"Printing Services",
        to:"/PrintingServices"
    },
    {
        title:"Contact Us",
        to:"/ContactUs"
    },
    {
        title:"Blog",
        to:"/Blog"
    }

]

export const FooterLinks =[
    {
        title: "Facebook",
        href: "https://www.facebook.com/profile.php?id=61557321862108",
        target: "_blank"
      },
      {
        title: "Instagram",
        href: "https://www.instagram.com/zephr.in_/",
        target:"_blank",
        rel:"noreferrer"
      },
      {
        title: "Mail",
        href: "mailto:contact@zephr.in",
        target: "_blank"
      },
    {
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/100898084/",
        target: "_blank"
      }
]