import React from 'react'
import About from '../Components/AboutUs/About'
import Navbar from '../Components/Nav/Nav'
import Footer from '../Components/Footer/Footer'
import ContactButton from "../Components/ContactUsButton/ContactButton"
function AboutUs() {
  return (
    <div>
        <Navbar/>
        <About/>
        <ContactButton />
        <Footer/>
        
    </div>
  )
}

export default AboutUs