import React from 'react'
import Navbar from '../Components/Nav/Nav'
import Footer from '../Components/Footer/Footer'

import ContactButton from '../Components/ContactUsButton/ContactButton';


const containerStyles = {
  width: '95%',
  height: '140vh',
  display: 'grid',
  overflow: 'hidden',
  margin: '0',
  listStyle: 'none',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  gap: '15px',
  padding: '15px',
 
  borderRadius: '20px',
  position: 'relative', 
};

const itemStyles = {
  background: 'white',
  borderRadius: '2%',
  
};


function PrintingServices() {
  return (
    <div>
      <Navbar/>
       
      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        <ul  style={containerStyles} >
          {/* Video */}
          <video
            autoPlay
            loop
            muted
            src="./Assets/Final Comp_1.mp4"
            alt="vid"
            className="item"
            
            style={{ ...itemStyles, gridColumn: 'span 2', width: '100%', height: '100%', objectFit: 'cover' }}
          />

          {/* Heading */}
          <div  style={{ ...itemStyles, gridColumn: 'span 2', display:'flex', justifyContent:'space-between' }}>
             <div style={{minWidth:'30%'}}>
            <h1 style={{fontFamily:'futura',margin:'auto', textTransform:'uppercase', fontSize:'6vh', paddingLeft:'20%', paddingTop:'20%'}}> Printing Services</h1>
             </div>
             <div style={{maxWidth:'50%', fontSize: '1rem', margin: 'auto' }}>
            
Transform your ideas into reality with our Printing Services. From vibrant business cards to eye-catching banners, we offer a range of high-quality printing solutions. Impress clients and elevate your brand with our professional printing services tailored to meet your unique needs.
             </div>
          </div>
          
        
        
        </ul>
      </div>


   
      <ContactButton />

       <Footer/>
    </div>
  )
}

export default PrintingServices