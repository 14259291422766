import React from 'react'
import "./Clients.css"
function Cients() {
  return (
    <div className='clients'>
       
        <div className="clienttext">
            <h1>Our Impact</h1>
            <p>
            We at Zephr, have successfully catered to 500+ clients, with our work tenure spanning around 25 years.
            <br/>
            From personalised designs to highest quality of products, we have left no stone unturned to deliver to our clients.
            <br/>
            Its our commitment to delivering quality, always and forever.
            <br/>
            <br/>
            -Founder, Zephr.
           
            </p>

        </div>
       
    </div>
  )
}

export default Cients